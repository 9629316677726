import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const UNMATCHED_ERROR = { unmatched: true };

export class PlcCommonValidators {
	/**
	 * Check if two controls has the same values
	 * @argument control1 the initial control name to compare with.
	 * @argument control2 the second control name that must match control1 value.
	 * @returns ```{ unmatched: true }``` if validation is incorrect and apply the error to the
	 * second control
	 */
	public static valuesAreEquals(
		control1: string,
		control2: string,
	): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.get(control1) || !control.get(control2)) return null;

			if (control.get(control1).value !== control.get(control2).value) {
				control.get(control2).setErrors(UNMATCHED_ERROR);
				return UNMATCHED_ERROR;
			}
		};
	}
}
